import './polyfills';
import { gsap } from 'gsap';
import Lenis from '@studio-freight/lenis';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ceOut, ceInOut, ceInFast, ceInOutFast, setKeyHandler } from './utilities';
import Mosaic from './mosaic';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {

  // https://gsap.com/docs/v3/Plugins/ScrollTrigger/static.normalizeScroll()/#solution
  ScrollTrigger.config({ ignoreMobileResize: true });


  // ------------------------
  //  ELEMENT & VAR DEFINITIONS
  // ------------------------

  // define elements
  const html = document.querySelector('html');
  const main = document.querySelector('main');
  const sectionHome = document.getElementById('home');
  const sectionWork = document.getElementById('work');
  const sectionPlay = document.getElementById('play');
  const sectionAbout = document.getElementById('about');
  const sectionContact = document.getElementById('contact');

  // define variables
  const dur = 0.8;
  const minTablet = 769;
  const minDesktop = 1024;
  const isTouch = ScrollTrigger.isTouch;

  // touchscreen?
  if (isTouch) {
    html.setAttribute('data-touch', true);
  }


  // ------------------------
  //  ANIMATION DEFINITIONS
  // ------------------------


  // + SMOOTH SCROLLING
  // https://github.com/studio-freight/lenis#gsap-scrolltrigger-integration

  // const lenis = new Lenis({
  //   duration: 0.5,
  // });
  // function raf(time) {
  //   lenis.raf(time);
  //   requestAnimationFrame(raf);
  // }
  // requestAnimationFrame(raf);

  const lenis = new Lenis({
    duration: 0.5,
  });
  lenis.on('scroll', ScrollTrigger.update);
  gsap.ticker.add((time) => lenis.raf(time * 1000));
  gsap.ticker.lagSmoothing(0);



  // + HOME

  gsap.from(sectionHome.querySelectorAll('h1 span, p span'), {
    top: 25,
    ease: ceOut,
    opacity: 0,
    duration: 0.3,
    stagger: 0.15,
    delay: 0.75
  });

  // headshot mosaic
  // not great on touchscreen so we'll animate in a different way
  if (isTouch) {
    gsap.to(document.querySelector('#headshot img'), {
      y: '100%',
      ease: 'linear',
      scrollTrigger: {
        trigger: sectionHome,
        start: 'top top',
        end: 'top -60%',
        scrub: true,
        toggleActions: 'play none reverse none',
        // markers: { startColor: 'deeppink', endColor: 'deeppink' },
        fastScrollEnd: true,
      },
    });
  } else {
    new Mosaic(PIXI);
  }




  // + SECTION PARALLAX

  gsap.from(sectionWork, {
    y: '-40vh',
    // ease: ceOut,
    scrollTrigger: {
      trigger: sectionHome,
      start: 'bottom bottom',
      end: 'bottom 10%',
      scrub: true,
      toggleActions: 'play none reverse none',
      // markers: { startColor: 'deeppink', endColor: 'deeppink' },
      fastScrollEnd: true,
    },
  });




  // done with GSAP stuff for "above-the-fold" and adjacent section; safe to remove anyno-gsap styling

  document.body.removeAttribute('data-js-loading');




  // + WORK

  const workSlidesContainer = document.getElementById('work-slides');
  const workSlidesList = workSlidesContainer.querySelector('ul');

  // gsap.to(workSlidesList, {
  //   '--slide-tween-pct': 100,
  //   ease: 'linear',
  //   scrollTrigger: {
  //     trigger: workSlidesContainer,
  //     start: 'top bottom',
  //     end: 'bottom top',
  //     scrub: 0.5,
  //     toggleActions: 'play none reverse none',
  //     // markers: { startColor: 'deeppink', endColor: 'deeppink' },
  //     fastScrollEnd: true,
  //   },
  // });

  let mm = ScrollTrigger.matchMedia();

  mm.add(`(max-width: ${minDesktop - 1}px)`, () => {
    gsap.fromTo(
      workSlidesList,
      {
        xPercent: 0,
      },
      {
        xPercent: () => (1 - (window.innerWidth / workSlidesList.clientWidth)) * -100,
        ease: 'linear',
        scrollTrigger: {
          trigger: workSlidesContainer,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5,
          toggleActions: 'play none reverse none',
          // markers: { startColor: 'deeppink', endColor: 'deeppink' },
          fastScrollEnd: true,
          invalidateOnRefresh: true,
          normalizeScroll: true,
        },
      },
    );

    return () => {};
  });

  mm.add(`(min-width: ${minDesktop}px)`, () => {
    gsap.fromTo(
      workSlidesList,
      {
        x: '3vw',
        y: () => window.innerHeight * -0.4,
      },
      {
        x: '-3vw',
        y: () => (workSlidesList.clientHeight * -1) - (window.innerHeight * -0.5),

        ease: 'linear',
        scrollTrigger: {
          trigger: workSlidesContainer,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5,
          toggleActions: 'play none reverse none',
          // markers: { startColor: 'deeppink', endColor: 'deeppink' },
          fastScrollEnd: true,
          invalidateOnRefresh: true,
        },
      },
    );

    return () => {};
  });





  // + PLAY

  const playText = document.getElementById('play-text');
  const playIndex = document.getElementById('experiments-container');
  const playPageContainer = document.getElementById('play-page-container');
  const playIframe = playPageContainer.querySelector('iframe');
  const playLinks = playIndex.querySelectorAll('li a');
  const btnPlayPageClose = document.getElementById('close-play');
  const bubbles = document.querySelectorAll('section#play .bubbles span');

  const playPageContainerActiveAttr = 'data-active';

  const playPageTimeline = gsap.timeline({
    paused: true,
    defaults: {
      ease: ceInOut,
      duration: 0.3,
    },
    onStart: () => {
      if (playPageContainer.hasAttribute(playPageContainerActiveAttr)) {
        return;
      }
      lenis.stop();
      playPageContainer.setAttribute(playPageContainerActiveAttr, true);
    },
    onReverseComplete: () => {
      playPageContainer.removeAttribute(playPageContainerActiveAttr);
      lenis.start();
    },
  });
  playPageTimeline.to(playText, {
    x: '-100vw',
  });
  playPageTimeline.to(playIndex, {
    x: '-100vw',
  }, '<0.1');
  playPageTimeline.to(playPageContainer, {
    x: '-100vw',
  }, '<0.1');
  playPageTimeline.to(playPageContainer, {
    ease: ceOut,
    height: '100vh',
  });

  const handleOnStartPlayPage = (url) => {
    playIframe.src = url;
  };

  const handleOnClosePlayPage = () => {
    if (!playPageContainer.hasAttribute(playPageContainerActiveAttr)) {
      return;
    }
    playIframe.src = 'about:blank';
    playPageTimeline.reverse();
  };

  const loadPlayPage = (url) => {
    playPageTimeline.eventCallback('onComplete', handleOnStartPlayPage, [url]);
    playPageTimeline.play();
  };

  playLinks.forEach((link) => {
    const url = link.getAttribute('href');
    link.addEventListener('click', (e) => {
      e.preventDefault();
      loadPlayPage(url);
    });
  });

  btnPlayPageClose.addEventListener('click', handleOnClosePlayPage);
  setKeyHandler('esc', handleOnClosePlayPage);

  // bubbles
  bubbles.forEach((bubble, i) => {
    const xDist = Math.ceil((Math.random() * 2) * (i + 1) * 8);
    const yDist = Math.ceil((Math.random() * 2) * (i + 1) * 8);
    const blurVal = Math.max(0, Math.floor(Math.random() * 20));

    gsap.fromTo(bubble, {
      x: `${xDist}vw`,
      y: `${yDist}vh`,
    },
    {
      x: `${-xDist}vw`,
      y: `${-yDist}vh`,
      ease: 'none',
      scrollTrigger: {
        trigger: sectionPlay,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1.0,
        toggleActions: 'play none reverse none',
        // markers: { startColor: 'deeppink', endColor: 'deeppink' },
        fastScrollEnd: true,
      },
    });

    gsap.fromTo(bubble, {
      filter: `blur(0px)`,
    },
    {
      filter: `blur(${blurVal}px)`,
      ease: 'none',
      scrollTrigger: {
        trigger: sectionPlay,
        start: 'top 50%',
        end: 'bottom 30%',
        scrub: 1.0,
        toggleActions: 'play none reverse none',
        // markers: { startColor: 'deeppink', endColor: 'deeppink' },
        fastScrollEnd: true,
      },
    });
  });

  // expanding bg
  gsap.from(sectionPlay, {
    '--play-bg-inset': '4vmin',
    ease: 'linear',
    scrollTrigger: {
      trigger: sectionPlay,
      start: 'top 80%',
      end: 'top 20%',
      scrub: 0.5,
      toggleActions: 'play none reverse none',
      // markers: { startColor: 'deeppink', endColor: 'deeppink' },
      fastScrollEnd: true,
    },
  });




  // + ABOUT

  const aboutHeadlineBlocks = document.querySelectorAll('.block--about');
  const skillsListTechnical = document.getElementById('skills-technical');
  const skillsListPersonal = document.getElementById('skills-personal');
  const experienceContainer = document.getElementById('experience-container');
  const workExperiencesList = document.getElementById('business-cards');
  const workExperiences = Array.from(workExperiencesList.children);
  const experienceYear = document.getElementById('experience-year');

  const aboutHeadlineAttr = 'data-revealed';
  const experienceProgressAttr = 'data-current';
  const experienceYearAttr = 'data-started';
  const experienceInterval = 0.4;

  // set
  experienceYear.innerText = parseInt(workExperiences[0].getAttribute(experienceYearAttr));

  // headline reveals
  aboutHeadlineBlocks.forEach((aboutHeadlineBlock) => {
    const revealables = aboutHeadlineBlock.querySelectorAll('.block-title__content-wrapper > *');
    gsap.to(revealables, {
      stagger: {
        each: 0.04,
        onStart: function(){ this.targets()[0].setAttribute(aboutHeadlineAttr, true); },
        onReverseComplete: function(){ this.targets()[0].removeAttribute(aboutHeadlineAttr); },
      },
      scrollTrigger: {
        trigger: aboutHeadlineBlock,
        start: 'top 80%',
        end: 'top 80%',
        // markers: true,
        toggleActions: 'play none reverse none',
        fastScrollEnd: true,
      }
    });
  });

  // skills reveals
  [skillsListTechnical, skillsListPersonal].forEach((skillsList) => {
    gsap.from(skillsList.children, {
      y: 40,
      opacity: 0,
      stagger: 0.04,
      scrollTrigger: {
        trigger: skillsList,
        start: 'top 90%',
        end: 'top 90%',
        // markers: true,
        toggleActions: 'play none reverse none',
        fastScrollEnd: true,
      }
    });
  });

  // work experience: year
  const aboutTimelineWorkYear = gsap.timeline({ paused: true, defaults: { duration: 0.4, ease: ceInOut } });
  workExperiences.forEach((li, j) => {
    const yr = parseInt(li.getAttribute(experienceYearAttr));
    aboutTimelineWorkYear.to(experienceYear, {
                            top: `${100 * j / workExperiences.length}%`
                          })
                          // https://gsap.com/community/forums/topic/26886-number-counter-animation-in-gsap-3x-and-adding-decimal-separators-to-the-number/?do=findComment&comment=131098
                          .to(experienceYear, {
                            textContent: yr,
                            snap: { textContent: 1 },
                            stagger: 1,
                          }, '<')
                          .addLabel(`interval${j + 1}`);
  });

  // work experience: positions
  const aboutTimelineWorkExperience = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: experienceContainer,
      start: 'top 60%',
      end: () => window.innerWidth < minTablet ? '+=600' : `+=${workExperiencesList.clientHeight * 3}`,
      // markers: true,
      scrub: true,
      toggleActions: 'play none reverse none',
      fastScrollEnd: true,
      invalidateOnRefresh: true,
    }
  });
  aboutTimelineWorkExperience
    .to(workExperiencesList, {
      onStart: () => workExperiencesList.setAttribute(experienceProgressAttr, 1),
      onReverseComplete: () => workExperiencesList.removeAttribute(experienceProgressAttr),
      duration: experienceInterval,
    })
    .to(workExperiencesList, {
      onStart: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 2);
        aboutTimelineWorkYear.tweenFromTo('interval1', 'interval2');
      },
      onReverseComplete: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 1);
        aboutTimelineWorkYear.tweenFromTo('interval2', 'interval1');
      },
      duration: experienceInterval,
    })
    .to(workExperiencesList, {
      onStart: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 3);
        aboutTimelineWorkYear.tweenFromTo('interval2', 'interval3');
      },
      onReverseComplete: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 2);
        aboutTimelineWorkYear.tweenFromTo('interval3', 'interval2');
      },
      duration: experienceInterval,
    })
    .to(workExperiencesList, {
      onStart: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 4);
        aboutTimelineWorkYear.tweenFromTo('interval3', 'interval4');
      },
      onReverseComplete: () => {
        workExperiencesList.setAttribute(experienceProgressAttr, 3);
        aboutTimelineWorkYear.tweenFromTo('interval4', 'interval3');
      },
      duration: experienceInterval,
    });



  // + CONTACT

  const contactHeadline = sectionContact.querySelector('h3');
  const contactHandwriting = contactHeadline.querySelectorAll('.written em');
  const contactSubhead = sectionContact.querySelector('h3 + p');
  const contactLinkList = sectionContact.querySelector('ul.contact-links');
  const contactLinks = contactLinkList.querySelectorAll('li');

  // parallax
  gsap.from(sectionContact, {
    y: '-40%',
    ease: 'linear',
    scrollTrigger: {
      trigger: sectionAbout,
      start: 'bottom bottom',
      endTrigger: main,
      end: 'bottom bottom',
      scrub: true,
      toggleActions: 'play none reverse none',
      // markers: { startColor: 'deeppink', endColor: 'deeppink' },
      fastScrollEnd: true,
    },
  });

  // headline & handwriting reveal
  let contactTimelineHeadline = gsap.timeline({ paused: true, defaults: { duration: 1.0 } });
  contactTimelineHeadline
    .addLabel('contact-start')
    .from(contactHeadline, {
      opacity: 0,
    })
    .from(contactHandwriting, {
      opacity: 0,
      stagger: 0.1,
    }, '<+0.6')
    .from(contactSubhead, {
      opacity: 0,
    }, 'contact-start+0.65')
    .from(contactLinkList, {
      '--rule-width': 0
    }, 'contact-start+0.7')
    .from(contactLinks, {
      y: 40,
      opacity: 0,
      stagger: 0.15,
    }, 'contact-start+0.8');

  ScrollTrigger.create({
    trigger: sectionAbout,
    start: 'bottom 75%',
    endTrigger: main,
    end: 'bottom bottom+=10%',
    // markers: { startColor: 'pink', endColor: 'pink' },
    animation: contactTimelineHeadline,
    scrub: 0.5,
    toggleActions: 'play none reverse none',
    fastScrollEnd: true,
  });




  // + FOOTER

  const footer = document.getElementById('footer');
  const footerMiniHeadshot = footer.querySelector('img.headshot');

  gsap.from(footerMiniHeadshot, {
    y: '3rem',
    ease: 'linear',
    scrollTrigger: {
      trigger: footer,
      start: 'top bottom',
      end: 'bottom bottom',
      scrub: 0.3,
      toggleActions: 'play none reverse none',
      // markers: { startColor: 'deeppink', endColor: 'deeppink' },
      fastScrollEnd: true,
    },
  });

});
