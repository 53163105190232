import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
// import { BloomFilter } from 'pixi-filters';
import debounce from 'debounce';
import rgbHex from 'rgb-hex';
import { ceOut, clamp } from './utilities';

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

class Mosaic {
  constructor(PIXI) {
    // init application
    this.sectionHome = document.getElementById('home');
    const headshot = document.getElementById('headshot');
    const headshotImg = headshot.querySelector('img');
    this.app = new PIXI.Application({
      width: headshot.clientWidth,
      height: headshot.clientHeight,
      antialias: true,
      resizeTo: headshot,
      // resizeThrottle: 250,
      backgroundAlpha: 0,
    });

    // append view
    headshot.appendChild(this.app.view);

    // get dimensions

    // const { w, h } = this.calculateDimensions();
    // console.log(w,h);
    // this.tileSize = h / MOSAIC.length;

    // let arrayImport = import('./mosaicData.json', {
    //   assert: { type: "json" },
    // });
    // const mosaicData = arrayImport.default;

    // const mosaicData = JSON.parse(readFileSync(new URL('./mosaic-data.json', import.meta.url), 'utf8'));
    // const mosaicData = require('./mosaic-data.json');

    // console.log(MOSAIC.length);
    // console.log(this.tileSize);

    // draw
    this.buildTiles();

    // // hide the static image
    // headshotImg.classList.add('is-hidden');

    // handle resize
    window.addEventListener('resize', debounce(this.handleResize, 100));
  }

  handleResize = () => {
    this.calculateDimensions();
    this.setHeadshotContainer();

    // // Get the parent (canvas element)
    // const parent = this.app.view.parentNode;

    // // Resize the renderer
    // this.app.renderer.resize(parent.clientWidth, parent.clientHeight);
    // console.log('parent dims:', parent.clientWidth, parent.clientHeight);

    // console.log('headshot dims:', this.hsw, this.hsh);
    // console.log('canvas dims:', this.cw, this.ch);



    // You can use the 'screen' property as the renderer visible
    // area, this is more useful than view.width/height because
    // it handles resolution
    // rect.position.set(app.screen.width, app.screen.height);
  };

  calculateDimensions = () => {
    // HEADSHOT DIMENSIONS SHOULD BE:
    //  - width: clamp(250px, 40vmax, 750px);
    //  - height: width * (1300 / 1150)
    const aspectRatio = 1150 / 1300;
    const hsw = clamp((Math.max(window.innerWidth, window.innerHeight) * 0.4), 250, 750);
    const hsh = hsw / aspectRatio;
    // return { hsw, hsh, cw: this.app.view.width, ch: this.app.view.height };
    this.hsw = hsw;
    this.hsh = hsh;
    this.cw = this.app.view.width;
    this.ch = this.app.view.height;
  };

  setHeadshotContainer = () => {
    this.container.width = this.hsw;
    this.container.height = this.hsh;
    this.container.x = this.cw - this.hsw;
    this.container.y = this.ch - this.hsh;
  };

  buildTiles = () => {
    // track refs for gsap
    const tiles = [];

    // get dimensions
    // const { hsw, hsh, cw, ch } = this.calculateDimensions();
    this.calculateDimensions();
    const tileSize = this.hsh / MOSAIC.length;
    const tileOffset = tileSize / -2;

    // create container for headshot
    this.container = new PIXI.Container();
    this.setHeadshotContainer();
    this.container.interactiveChildren = false;

    this.app.stage.addChild(this.container);

    // // create rect template
    // const template = new PIXI.Graphics()
    //   .beginFill(0x00ff00)
    //   .drawRect(
    //     tileOffset,
    //     tileOffset,
    //     tileSize,
    //     tileSize
    //   );

    // set transform values for each tile
    MOSAIC.forEach((row, i) => {
      row.forEach((tile, j) => {
        if (tile) {
          const rect = new PIXI.Graphics()
            .beginFill(rgbHex(tile))
            .drawRect(
              tileOffset,
              tileOffset,
              tileSize,
              tileSize
            )
            .endFill();
          rect.position.set((j * tileSize) - tileOffset, (i * tileSize) - tileOffset);
          // rect.filters = [bloomFilter];
          tiles.push(rect);
          this.container.addChild(rect);
        }
      });
    });

    // const headshotWidthRatio = 0.4; // headshotWidth / window.innerWidth;
    // const headshotHeightRatio = 0.4; // headshotWidth * (mosaicRowCount / mosaicColCount) / window.innerHeight;

    // calculate gsap transforms and tie to scroll
    // const blurFilter = new PIXI.BlurFilter();
    // blurFilter.blur = 0;
    // blurFilter.quality = 2;

    tiles.forEach((tile) => {
      const xVal = ((((this.cw + (2 * this.hsw)) * Math.random()) - this.hsw) * -1) + (this.hsw / 3);
      const yVal = (((this.ch + (2 * this.hsh)) * Math.random()) - this.hsh) * -1;

      const scaleVal = (Math.random() * 4) - 2;
      const rotateVal = Math.round(Math.random() * 360 * 2);
      const alphaVal = (scaleVal + 2) / 4;
      // if (scaleVal > 1.5 || scaleVal < -1.5) {
      //   tile.filters = [blurFilter];
      // }

      gsap.to(tile, {
        pixi: {
          x: xVal,
          y: yVal,
          scale: scaleVal,
          // blur: blurVal,
          rotation: rotateVal,
          alpha: alphaVal,
        },
        // z: `${zVal}px`,
        // filter: `blur(${blurVal}px)`,
        ease: ceOut,
        scrollTrigger: {
          trigger: this.sectionHome,
          start: 'bottom bottom',
          end: 'bottom 30%',
          scrub: 0.5,
          toggleActions: 'play none reverse none',
          // markers: { startColor: 'deeppink', endColor: 'deeppink' },
          fastScrollEnd: true,
        },
      });
    });



    // const mosaicColCount = 46;
    // const mosaicRowCount = 52;
    // const headshotWidth = Math.floor(window.innerWidth * 0.4 / mosaicColCount) * mosaicColCount;
    // const headshotWidthRatio = headshotWidth / window.innerWidth;
    // const headshotHeightRatio = headshotWidth * (mosaicRowCount / mosaicColCount) / window.innerHeight;
    // gsap.to(':root', {
    //   '--headshot-width': `${headshotWidth}px`
    // });

    // document.querySelectorAll('#headshot > div').forEach((div) => {
    //   const xVal = headshotWidthRatio * window.innerWidth * Math.random() * -2;
    //   const yVal = headshotHeightRatio * window.innerHeight * Math.random() * -2;
    //   const hasPerspective = Math.random() > 0.7;
    //   const zVal = ((Math.random() * 8) - 4) * 100;
    //   const blurVal = hasPerspective ? Math.floor(Math.random() * 20) : 0;
    //   gsap.to(div, {
    //     x: `${xVal}px`,
    //     y: `${yVal}px`,
    //     z: `${zVal}px`,
    //     filter: `blur(${blurVal}px)`,
    //     ease: ceOut,
    //     scrollTrigger: {
    //       trigger: sectionHome,
    //       start: 'bottom bottom',
    //       end: 'bottom 30%',
    //       scrub: 0.5,
    //       toggleActions: 'play none reverse none',
    //       // markers: { startColor: 'deeppink', endColor: 'deeppink' },
    //       fastScrollEnd: true,
    //     },
    //   });
    // });

  };
}

export default Mosaic;
