import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

// define eases
export const ceOut = CustomEase.create('custom', '0, 0.66, 0.5, 1');
export const ceOutLong = CustomEase.create('custom', '0.26, 1, 0.56, 1');
export const ceInOut = CustomEase.create('custom', '0.76, 0, 0.24, 1');
export const ceInFast = CustomEase.create('custom', '0.94, 0, 0.83, 0.28');
export const ceInOutFast = CustomEase.create('custom', '1, 0, 0.65, 1');
export const ceBounceOut = CustomEase.create('custom', '0.620, 0.230, 0.690, 1.425');
export const cePop = CustomEase.create('custom', '0.175, 0.885, 0.315, 1.650');

// custom counting effect
// https://greensock.com/forums/topic/26886-number-counter-animation-in-gsap-3x-and-adding-decimal-separators-to-the-number/?do=findComment&comment=131102
// load with `gsap.registerEffect`
export const counterEffect = {
  name: 'counter',
  extendTimeline: true,
  defaults: {
    end: 0,
    duration: 0.6,
    ease: ceOut,
    increment: 1,
    stagger: 0,
  },
  effect: (targets, config) => {
    let tl = gsap.timeline({
      defaults: {
        duration: config.duration, 
        modifiers: {
          innerText: function(innerText){
            // return gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
          }
        },
        ease: config.ease,
      }
    });

    // animate
    targets.forEach((target) => {
      tl.to(target, {
        innerText: target.innerText.replace(/\,/g, ''), 
      }, `<+=${config.stagger}`);
      target.innerText = '0';
    });
    
    return tl;
  }
};

// get an element's position relative to window
export const getOffsetTop  = (element) => element.getBoundingClientRect().top + window.scrollY;
export const getOffsetLeft = (element) => element.getBoundingClientRect().left + window.scrollX;

// clamp
export const clamp = (val, min = 0, max = 1) => Math.max(min, Math.min(max, val));

// key events
const keyPressed = (e) => {
  if (e.defaultPrevented) {
    return null;
  }

  const key = e.key || e.keyCode;

  switch (true) {
    case ['Escape', 'Esc', 27].includes(key):
      return 'esc';
    default:
      return null;
  }
};

export const setKeyHandler = (key, fn) => {
  const handler = (e) => {
    if (keyPressed(e) === key) {
      fn();
    }
  };
  document.addEventListener('keyup', handler);
};
